type Props = {
  children: React.ReactNode;
};

function FormWrapper({ children }: Props) {
  return (
    <section className="flex w-full items-center justify-center space-y-4 overflow-x-hidden p-6">
      <div className="w-full max-w-lg space-y-4">{children}</div>
    </section>
  );
}

export default FormWrapper;
