import clsx from 'clsx';

type InfoTextVariant = 'default' | 'warning' | 'error' | 'success' | 'info' | 'dark';

type InfoTextProps = {
  children: React.ReactNode;
  variant?: InfoTextVariant;
};

function InfoText({ children, variant = 'default' }: InfoTextProps) {
  return (
    <p
      className={clsx('inline-block w-auto rounded-base text-sm leading-normal pt-1', {
        'text-gray-500': variant === 'default',
        'text-red-900 bg-blue-50 border-blue-400 border': variant === 'info',
        'text-green-900 bg-green-50 border-green-500 border': variant === 'success',
        'text-red-900 bg-red-50 border-red-400 border': variant === 'error',
        'px-2 py-1': variant !== 'default',
      })}
    >
      {children}
    </p>
  );
}

export default InfoText;
