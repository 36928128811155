import clsx from 'clsx';
import React, { forwardRef } from 'react';

export const Spinner = () => (
  <svg
    viewBox="0 0 20 20"
    width="20"
    className="origin-center animate-spin fill-current duration-100"
    aria-label="Loading"
  >
    <path d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z" />
  </svg>
);

export type ButtonProps = {
  /**
   * What background color to use
   */
  variant?: 'primary' | 'secondary' | 'gray' | 'green' | 'red' | 'blue' | 'yellow' | 'transparent' | 'fancy';
  /**
   * Is the button outlined?
   */
  outline?: boolean;
  /**
   * How large should the button be?
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit' | 'reset';
  /**
   * Optional click handler
   */
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  /**
   * The button label
   */
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  className?: string;
};

/**
 * Primary UI component for user interaction
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    variant = 'primary',
    size = 'sm',
    type = 'button',
    outline,
    onClick,
    disabled,
    icon,
    className,
    loading,
    children,
    ...props
  }: ButtonProps,
  ref,
) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'relative flex-shrink-0 rounded-md border transition-all',
        {
          //Sizes
          'px-2.5 py-1 text-xs font-normal': size === 'xs',
          'px-3.5 py-2 text-sm font-normal': size === 'sm',
          'px-4 py-2.5 text-sm font-normal': size === 'md',
          'px-5 py-2.5 text-base font-normal': size === 'lg',

          //Colours
          'text-white fill-white': !outline,
          'text-gray-900 fill-gray-900 bg-white' : outline,
          'bg-white bg-opacity-50 border-none text-gray-900' : variant === 'transparent',
          'bg-yellow-500 text-neutral-900 border border-neutral-900 rounded-sm !shadow-[6px_6px_rgba(236,102,61,0.9)]' : variant === 'fancy',
          'border-primary-500 bg-primary-500 hover:bg-primary-600': variant === 'primary' && !outline,
          'border-primary-200 bg-primary-100 text-primary-400 hover:bg-primary-100': variant === 'primary' && disabled && !outline,
          'border-red-500 bg-red-500': variant === 'red' && !outline,
          'border-green-500 bg-green-500': variant === 'green' && !outline,
          'border-blue-500 bg-blue-500': variant === 'blue' && !outline,
          'border-yellow-500 bg-yellow-500': variant === 'yellow' && !outline,
          'border-secondary-500 bg-secondary-500': variant === 'secondary' && !outline,
          'border-gray-200': variant === 'primary' && outline,
          'text-red-500 border-red-200': variant === 'red' && outline,
          'text-green-100': variant === 'green' && outline,
          'text-yellow-100': variant === 'yellow' && outline,
          'text-blue-100': variant === 'blue' && outline,
          'text-secondary-600 border-secondary-200 hover:bg-secondary-50': variant === 'secondary' && outline,
          'text-gray-600 border-gray-200 hover:bg-gray-50 bg-white': variant === 'gray' && outline,
          'transition-all ease-in-out border-opacity-0 bg-primary-100 !shadow-[6px_6px_rgba(0,0,0,0.0)] text-opacity-50': disabled && !loading && variant !== 'fancy',
          'transition-all ease-in-out border-opacity-0 bg-yellow-100 !shadow-[6px_6px_rgba(0,0,0,0.0)] text-opacity-50': disabled && !loading && variant === 'fancy',
          'border-opacity-0 bg-opacity-40 text-opacity-50': loading,
        },
        className,
      )}
      {...props}
      {...(ref ? { ref } : {})}
    >
      <span className="flex items-center justify-center space-x-2">
        {icon}
        <span>{children}</span>
      </span>
      {loading && (
        <span className="absolute top-0 left-0 flex h-full w-full items-center justify-center text-white">
          <Spinner />
        </span>
      )}
    </button>
  );
});

export default Button;
