import clsx from 'clsx';

type LabelProps = {
  name: string;
  value: string | number;
  children: string;
  style?: string;
};

function Label(props: LabelProps) {
  const { name, value, children, style } = props;
  return (
    <label
      htmlFor={name}
      className={clsx(
        'absolute top-0 left-0 inline-block w-8/12 truncate px-[11px] pt-[6px] text-xs text-gray-900',
        'pointer-events-none origin-left',
        { filled: value },
        
      )}
    >
      {children}
    </label>
  );
}

export default Label;
