import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

type Errors = {
  [key: string]: { message: string }[];
};

export function convertKeysToCamelCase(object: Object) {
  return mapKeys(object, (value, key) => camelCase(key));
}

function mapErrorsToForm(errors: Errors) {
  const formErrors: { [key: string]: string[] } = {};

  for (const key in errors) {
    const errorList = errors[key];
    formErrors[key] = errorList?.map?.(err => err.message);
  }

  return formErrors;
}

export default mapErrorsToForm;
