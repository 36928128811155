import NextLink, { LinkProps } from 'next/link';

type Props = LinkProps & {
  children: React.ReactNode;
};

function Link({ href, children }: Props) {
  return (
    <NextLink href={href}>
      <a className="text-system-link underline">{children}</a>
    </NextLink>
  );
}

export default Link;
